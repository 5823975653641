<template>
  <el-tag
    :type="isUseLattePayment ? 'primary' : 'info'"
    size="small"
    :title="isUseLattePayment ? 'LatteX Payment' : 'Seller Payment'"
  >
    {{ isUseLattePayment ? 'LatteX Payment' : 'Seller Payment' }}
  </el-tag>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  props: {
    isUseLattePayment: { type: Boolean, required: true },
  },
  setup() {
    return {}
  },
})
</script>
