import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_IsUseLattePaymentTag = _resolveComponent("IsUseLattePaymentTag")!
  const _component_StoreTypeTag = _resolveComponent("StoreTypeTag")!
  const _component_StatusTag = _resolveComponent("StatusTag")!
  const _component_edit = _resolveComponent("edit")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_delete = _resolveComponent("delete")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_Update = _resolveComponent("Update")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_el_table, {
      ref: "multipleTable",
      data: _ctx.documents,
      style: {"width":"100%"},
      lazy: _ctx.isLoading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          property: "createdAt",
          label: "Created",
          "min-width": 10
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.formatDateTime(scope.row.createdAt)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          property: "name",
          label: "Store name",
          "min-width": 10
        }),
        _createVNode(_component_el_table_column, {
          property: "appShopUrl",
          label: "Sub Domain",
          "min-width": 15
        }),
        _createVNode(_component_el_table_column, {
          property: "isUseLattePayment",
          label: "Payments",
          "min-width": 10
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_IsUseLattePaymentTag, {
              isUseLattePayment: scope.row.isUseLattePayment
            }, null, 8, ["isUseLattePayment"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          property: "storeType",
          label: "Store Type",
          "min-width": 8
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_StoreTypeTag, {
              storeType: scope.row.storeType,
              "sub-domain": scope.row.appShopUrl
            }, null, 8, ["storeType", "sub-domain"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          property: "activated",
          label: "Status",
          "min-width": 8
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_StatusTag, {
              status: scope.row.activated,
              trueText: "Active",
              falseText: "In Active"
            }, null, 8, ["status"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          property: "syncLastTime",
          label: "Last Sync",
          "min-width": 10
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.formatTime(scope.row.syncLastTime)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          property: "pullOrderTime",
          label: "Pull Order Time",
          "min-width": 8
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(`${scope.row.pullOrderTime} days`), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          property: "subscriptionId",
          label: "Subcription",
          "min-width": 10
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row?.subscriptionId?.code ?? '-'), 1)
          ]),
          _: 1
        }),
        (_ctx.userInfo?.userType === 'agency')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 0,
              property: "sellerTeam",
              label: "Seller",
              "min-width": 10
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(scope.row.sellerTeam?.name ?? '-'), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_el_table_column, {
          label: "Actions",
          "min-width": 10
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              size: "small",
              onClick: ($event: any) => (_ctx.openUpdateDialog(scope.row))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_edit)
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_el_button, {
              size: "small",
              type: "danger",
              onClick: ($event: any) => (_ctx.handleDeleteStore(scope.row))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_delete)
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data", "lazy"]), [
      [_directive_loading, _ctx.isLoading]
    ]),
    _createVNode(_component_Update, {
      ref: "updateStoreDialog",
      onForceUpdate: _ctx.forceUpdate
    }, null, 8, ["onForceUpdate"])
  ], 64))
}