
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  props: {
    storeType: { type: String, required: true },
    subDomain: { type: String, required: true },
  },
  computed: {
    imagePlatform() {
      if (this.subDomain.includes('ecomzi.io'))
        return 'https://resize.latteprint.com/img/100/100/resize/2023-03-31/1718267947-logo.png'
      switch (this.storeType) {
        case 'lattehub':
          return 'https://resize.latteprint.com/img/100/100/resize/2023-03-31/1680238578698_logo-9850d37.png'
        case 'shopbase':
          return 'https://resize.latteprint.com/img/100/100/resize/2022-06-21/1655784073220_60c0b34e6e19ad0e0831fd6c-PlusBase-Logo-Dark-Color-p-500.png'
        case 'shopify':
          return 'https://resize.latteprint.com/img/100/100/resize/2022-06-21/1655784235472_logo.png'
        default:
          return ''
      }
    },
  },
})
