
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  components: {},
  props: {
    status: {
      type: Boolean,
      required: true,
    },
    trueText: {
      type: String,
    },
    falseText: {
      type: String,
    },
  },
  setup(props) {
    const displayText = ref('unknown')
    const tagType = ref('info')

    const getType = () => {
      tagType.value = props.status ? 'success' : 'danger'
    }

    const getText = () => {
      displayText.value = props.status ? props.trueText || 'true' : props.falseText || 'false'
    }

    watch(
      () => props.status,
      () => {
        getType()
        getText()
      }
    )

    getType()
    getText()

    return { displayText, tagType }
  },
})
