
import { computed, reactive, watch, ref, defineComponent, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData } from '@/composables'
import Create from './components/Create.vue'
import { ElMessageBox } from 'element-plus'
import { deleteHandler } from '@/services/deleteHandler'
import { useNotification } from '@/composables'
import FilterTable from '@/components/common/FilterTable.vue'
import List from './components/List.vue'
import Pagination from '@/components/common/Pagination.vue'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Create,
    FilterTable,
    List,
    Pagination,
  },
  setup() {
    const breadcrumbs = ['Integrations', '']
    const title = 'Integrations'
    const baseResource = 'shops'
    const titleCreate = 'Create'
    const content = computed(() => {
      return { breadcrumbs, title, titleCreate }
    })
    // Add guide for bambooz
    const url = ref(`https://crmlattehub.s3.us-east-2.amazonaws.com/latteprint-resource/latteprint-connect-to-store.docx`)
    if (window.location.host === process.env.VUE_APP_BAMBOOZ_FULFILL_SITE) {
      url.value = 'https://static.bambooz.io/guides/Bambooz-connect-to-store.docx'
    }

    const { error, success } = useNotification()

    const params = reactive({ page: 1, limit: 10 })

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )

    onMounted(async () => {
      await fetchData()
    })

    watch(params, fetchData)

    const createStoreDialog = ref<InstanceType<typeof Create>>()
    const onCreateStore = () => {
      createStoreDialog.value?.toggle()
    }

    const onDeleteStore = (store: any) => {
      ElMessageBox.confirm(`Are you sure to delete this store?`)
        .then(async () => {
          const { deleteById, isLoading } = deleteHandler(baseResource)
          const { status, data } = await deleteById(store.id)
          if (status === 200) {
            success('Deleted')
            window.location.reload()
          } else {
            error(data.message)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    const onAfterChanged = () => {
      success('Update success!')
      fetchData()
    }

    return {
      onAfterChanged,
      params,
      content,
      url,
      documents,
      isLoading,
      onCreateStore,
      createStoreDialog,
      onDeleteStore,
      total,
    }
  },
})
