
import { defineComponent, reactive, ref } from 'vue'
import { getStoreTypes, IStore, getSaleTypes } from '@/interfaces/store'
import { required, stringRules } from '@/utils/formRules'
import { useResource } from '@/services'
import { updateHandler } from '@/services/updateHandler'

export default defineComponent({
  setup(props: any, { emit }) {
    const dialogVisible = ref(false)
    const formCreateStore = ref()
    // let teams = ref([])
    const baseResource = 'shops'
    const storeId = ref('')
    const loading = ref(false)
    const isEdit = ref(false)
    const state = reactive<{ store: IStore }>({
      store: {
        name: '',
        team: '',
        link: '',
        payment: '',
        activated: false,
        isUseDesignService: false,
        lastSyncDate: '',
        pullOrderDate: 7,
        storeType: '',
        tracking: '',
        appShopUrl: '',
        appApiKey: '',
        appPassword: '',
        appShareSecret: '',
        pullOrderTime: 1,
        saleType: '',
      },
    })
    const formRules = {
      name: stringRules('name', 2, 128),
      storeType: required('Store type'),
      appShopUrl: required('App shop url'),
      appApiKey: required('App API key'),
      appPassword: required('App password'),
    }
    const storeTypes = getStoreTypes()
    const saleTypes = getSaleTypes()
    const pullOrderDates = [
      {
        label: 'Last 1 days',
        value: 1,
      },
      {
        label: 'Last 3 days',
        value: 3,
      },
      {
        label: 'Last 5 days',
        value: 5,
      },
      {
        label: 'Last 7 days',
        value: 7,
      },
      {
        label: 'Last 21 days',
        value: 21,
      },
      {
        label: 'Last 90 days',
        value: 90,
      },
      {
        label: 'Last 120 days',
        value: 120,
      },
      {
        label: 'Last 240 days',
        value: 240,
      },
      {
        label: 'Last 360 days',
        value: 360,
      },
    ]

    const onSubmit = () => {
      formCreateStore.value.validate(async (valid: boolean) => {
        if (valid) {
          const resource = `${baseResource}/${storeId.value}`
          const { update, isLoading } = updateHandler(resource, state.store)
          loading.value = Boolean(isLoading)
          const { status } = await update()
          if (status === 200 || status === 201) {
            dialogVisible.value = false
            emit('forceUpdate')
          }
        }
      })
    }

    const fectchRecord = async (id: string) => {
      loading.value = true
      const resource = `${baseResource}`
      const { get } = useResource(resource)
      const { data, status } = await get(id)
      if (status === 200) {
        const response = data as any
        state.store = response
      }
      loading.value = false
    }

    const toggle = (id: string) => {
      if (id) {
        dialogVisible.value = true
        storeId.value = id
      }
      fectchRecord(id)
    }

    return {
      state,
      onSubmit,
      dialogVisible,
      toggle,
      formCreateStore,
      formRules,
      loading,
      isEdit,
      fectchRecord,
      storeTypes,
      pullOrderDates,
      saleTypes,
    }
  },
})
