
import { defineComponent, ref } from 'vue'
import { Edit, Delete } from '@element-plus/icons'
import Update from './Update.vue'
import { deleteHandler } from '@/services/deleteHandler'
import { ElMessageBox } from 'element-plus'
import { useNotification } from '@/composables'
import { formatDateTime, formatTime } from '@/utils/dateTime'
import IsUseLattePaymentTag from '@/components/orders/display/IsUseLattePaymentTag.vue'
import StoreTypeTag from '@/components/orders/display/StoreTypeTag.vue'
import StatusTag from '@/components/common/tags/StatusTag.vue'

export default defineComponent({
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Edit,
    Delete,
    Update,
    IsUseLattePaymentTag,
    StoreTypeTag,
    StatusTag,
  },
  setup(props: any, context: any) {
    let userInfo: any
    try {
      userInfo = JSON.parse(localStorage?.getItem('info') || '')
    } catch (e) {
      console.log(e)
    }
    const { error, success } = useNotification()
    const baseResource = 'shops'
    let loading = ref(false)

    const handleDeleteStore = async (store: any) => {
      ElMessageBox.confirm(`Are you sure to delete this store?`)
        .then(async () => {
          const { deleteById, isLoading } = deleteHandler(baseResource)
          loading = isLoading
          const { status, data } = await deleteById(store.id)
          if (status === 200) {
            context.emit('updateFinish')
          } else {
            error(data.message)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    const forceUpdate = () => {
      context.emit('updateFinish')
    }

    const updateStoreDialog = ref<InstanceType<typeof Update>>()
    const openUpdateDialog = (store: any) => {
      updateStoreDialog.value?.toggle(store.id)
    }

    return {
      loading,
      openUpdateDialog,
      handleDeleteStore,
      updateStoreDialog,
      forceUpdate,
      formatDateTime,
      formatTime,
      userInfo,
    }
  },
})
