import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!

  return (!!_ctx.imagePlatform)
    ? (_openBlock(), _createBlock(_component_el_image, {
        key: 0,
        style: {"height":"15px"},
        src: _ctx.imagePlatform
      }, null, 8, ["src"]))
    : _createCommentVNode("", true)
}