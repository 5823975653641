export interface IStore {
  name: string
  team: string
  link: string
  storeType: string
  payment: string
  activated: boolean
  isUseDesignService: boolean
  lastSyncDate: string
  pullOrderDate: number
  tracking: string
  appShopUrl: string
  appApiKey: string
  appPassword: string
  appShareSecret: string
  pullOrderTime: number
  saleType: string
}
export const getStoreTypes = () => {
  return [
    {
      value: 'lattehub',
      label: 'LatteX',
    },
    {
      value: 'shopbase',
      label: 'Shopbase',
    },
    {
      value: 'shopify',
      label: 'Shopify',
    },
    {
      value: 'woo',
      label: 'Woo',
    },
  ]
}
export const getSaleTypes = () => {
  return [
    {
      value: 'pod',
      label: 'POD',
    },
    {
      value: 'drop',
      label: 'DROP',
    },
  ]
}
