
import { defineComponent, reactive, ref, computed } from 'vue'
import { IStore, getStoreTypes, getSaleTypes } from '@/interfaces/store'
import { required, stringRules } from '@/utils/formRules'
import { createHandler } from '@/services/createHandler'
import { useNotification } from '@/composables'
import { isLatteAgency } from '@/utils/agencyUtils'

export default defineComponent({
  setup(props: any, context: any) {
    const { error, success } = useNotification()

    const dialogVisible = ref(false)
    const formCreateStore = ref()
    const baseResource = 'shops'
    const loading = ref(false)
    const state = reactive<{ store: IStore }>({
      store: {
        name: '',
        team: '',
        link: '',
        payment: '',
        activated: true,
        isUseDesignService: false,
        lastSyncDate: '',
        pullOrderDate: 7,
        storeType: 'lattehub',
        saleType: 'pod',
        tracking: '',
        appShopUrl: '',
        appApiKey: '',
        appPassword: '',
        appShareSecret: '',
        pullOrderTime: 7,
      },
    })
    const formRules = {
      name: stringRules('name', 2, 128),
      storeType: required('Store type'),
      appShopUrl: required('App shop url'),
      appApiKey: required('App API key'),
      appPassword: required('App password'),
    }
    const storeTypes = getStoreTypes()
    const saleTypes = computed(() => {
      let defaultSaleTypes = getSaleTypes();
      // if(isLatteAgency())
      //   defaultSaleTypes.push({
      //     value: 'fake',
      //     label: 'FK',
      //   })
      return defaultSaleTypes
    })
    const pullOrderDates = [
      {
        label: 'Last 1 days',
        value: 1,
      },
      {
        label: 'Last 3 days',
        value: 3,
      },
      {
        label: 'Last 5 days',
        value: 5,
      },
      {
        label: 'Last 7 days',
        value: 7,
      },
      {
        label: 'Last 21 days',
        value: 21,
      },
      {
        label: 'Last 90 days',
        value: 90,
      },
      {
        label: 'Last 120 days',
        value: 120,
      },
      {
        label: 'Last 240 days',
        value: 240,
      },
      {
        label: 'Last 360 days',
        value: 360,
      },
    ]

    const onSubmit = () => {
      formCreateStore.value.validate(async (valid: boolean) => {
        if (valid) {
          const { create, isLoading } = createHandler(baseResource, state.store)
          loading.value = isLoading.value
          const { status, data } = await create()
          if (status === 201 || status === 200) {
            dialogVisible.value = false
            context.emit('onSubmitCreate')
          } else {
            error(data.message)
          }
        }
      })
    }

    const toggle = () => {
      dialogVisible.value = true
    }

    return {
      state,
      onSubmit,
      dialogVisible,
      toggle,
      formCreateStore,
      formRules,
      loading,
      storeTypes,
      pullOrderDates,
      saleTypes,
    }
  },
})
